export function convertMilesToMeters(miles: number) {
    var meters = miles * 1609.34;
    return meters;
}

export function getMinutes(str: string) {
    return parseFloat(str.substring(4, 10))
}

export function getDegrees(str: string) {
    return parseFloat(str.substring(0, 3))
}

export function getDirection(str: string) {
    return str.substring(11, 12)
}

export function converterCoordenadasLongitude(longitudeGraus: number, longitudeMinutos: number, longitudeWE: string) {
    return (longitudeGraus + longitudeMinutos / 60) * (longitudeWE == 'W' ? -1 : 1);
}

export function converterCoordenadasLatitude(latitudeGraus: number, latitudeMinutos: number, latitudeNS: string) {
    return (latitudeGraus + latitudeMinutos / 60) * (latitudeNS == 'S' ? -1 : 1);
}

export function convertLatToCoord(latitude: number) {
    const latDirection = latitude >= 0 ? 'N' : 'S';
    const latDegrees = Math.floor(Math.abs(latitude));
    const latMinutes = (Math.abs(latitude) - latDegrees) * 60;

    return `${latDegrees.toString().padStart(3, '0')}°${latMinutes.toFixed(3).toString().padStart(6, '0')}'${latDirection}`;
}

export function convertLngToCoord(longitude: number) {
    const lonDirection = longitude >= 0 ? 'E' : 'W';
    const lonDegrees = Math.floor(Math.abs(longitude));
    const lonMinutes = (Math.abs(longitude) - lonDegrees) * 60;

    return `${lonDegrees.toString().padStart(3, '0')}°${lonMinutes.toFixed(3).toString().padStart(6, '0')}'${lonDirection}`;
}
export function padNumber2(n: number) {
    return n.toString().padStart(2, '0')
}

export function formatDateTime(date: Date) {
    return `${padNumber2(date.getDate())}/${padNumber2(date.getMonth() + 1)}/${padNumber2(date.getFullYear())} ${padNumber2(date.getHours())}:${padNumber2(date.getMinutes())}:${padNumber2(date.getSeconds())}`
}