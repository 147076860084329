import { ref } from "vue"
import { useAudio } from "./audio"
import { useRecorder } from "./recorder"
import { useSession } from "./session"

const messages = ref([] as string[])
const playerListRef = ref(null as null | HTMLDivElement)
const playerRoomListRef = ref(null as null | HTMLDivElement) //MUST BE INITALIZED
const roomList = ref({} as { [key: string]: HTMLDivElement })
const playersRTCs = ref({} as { [key: string]: { video: HTMLVideoElement, startRecord: () => void, stopRecord: () => void } })

export function usePage() {
    function addVideoPlayer(stream: MediaStream, nickname: string, mmsi: string) {
        var template = new DOMParser().parseFromString(`<div class="player"> <span class="new badge user-badge" data-badge-caption="">${nickname}</span><div class="videoWrapper"><video id="webrtc-player-${mmsi}" class="user-div" autoplay></video></div></div>`, "text/html");
        template.getElementsByTagName("video")[0].srcObject = stream;
        var divPlayer = template.body.childNodes[0];
        if (playerListRef.value)
            playerListRef.value.appendChild(divPlayer);
        else
            alert("Player Channel List Ref Not initialized");

        const videoElement = divPlayer.childNodes[2].childNodes[0] as HTMLVideoElement;

        videoElement.volume = useAudio().generalVolume.value;
        const { start, stop } = useRecorder(stream, mmsi, (file) => { useAudio().pushReceivedAudio({ file, created: new Date(), mmsi, channel: useSession().channel.value }) })

        playersRTCs.value[mmsi] = { video: videoElement, startRecord: start, stopRecord: stop };

        return divPlayer;
    }

    function updateRoomPlayer(mmsi: string, nickname: string, channel: any, asRoot?: boolean) {
        if (!roomList.value[mmsi]) {
            if (playerRoomListRef.value) {
                var template = document.createElement("div");
                template.className = "app-pill";
                template.setAttribute("id", `mmsi-tag-user-${mmsi}`);
                template.innerHTML = `<div class='key'>${(asRoot ? '★' : '') + " " + nickname}</div> <div class='value'>${channel}</div>`;
                playerRoomListRef.value.appendChild(template);
                roomList.value[mmsi] = template;
            } else {
                alert("Player Room List Ref Not initialized")
            }
        } else {
            roomList.value[mmsi].innerHTML = `<div class='key'>${(asRoot ? '★' : '') + " " + nickname}</div> <div class='value'>${channel}</div>`;
        }
    }

    function removeRoomPlayer(mmsi: string) {
        if (roomList.value[mmsi]) {
            if (playerRoomListRef.value) {
                playerRoomListRef.value.removeChild(roomList.value[mmsi]);
                delete roomList.value[mmsi];
            } else {
                alert("Player Room List Ref Not initialized")
            }
        }
    }


    return { messages, playerListRef, playersRTCs, playerRoomListRef, addVideoPlayer, updateRoomPlayer, removeRoomPlayer }
}
