
import axios from 'axios';

const $axios = axios.create({
    baseURL: (process.env.VUE_APP_ENV == 'DEV' ? "http://localhost:3000" : "") + "",
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
    },
})

interface apirequestoptions {
    headers?: any
}

export function useApi() {

    async function useDelete(path: string, opt: apirequestoptions = {}) {
        try {
            const apiresponse = await $axios.delete(path, { headers: opt.headers })
            const data = apiresponse.data;
            if (data.error && data.message) {
                alert(data.message);
                return;
            }
            return data;
        } catch (e: any) {
            alert(e.message)
            console.error(e)
        }
    }

    async function usePost(path: string, body: object, opt: apirequestoptions = {}) {
        try {
            const apiresponse = await $axios.post(path, body, { headers: opt.headers })
            const data = apiresponse.data;
            if (data.error && data.message) {
                alert(data.message);
                return;
            }
            return data;
        } catch (e: any) {
            alert(e.message)
            console.error(e)
        }
    }

    async function useGet(path: string, opt: apirequestoptions = {}) {
        try {
            const apiresponse = await $axios.get(path, { headers: opt.headers })
            const data = apiresponse.data;
            if (data.error && data.message) {
                alert(data.message);
                return;
            }
            return data;
        } catch (e: any) {
            alert(e.message)
            console.error(e)
        }
    }

    return { usePost, useGet, useDelete }
}