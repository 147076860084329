import { useAudio } from "@/composables/audio";

export class User {
    id: string;
    mmsi: string;
    nickname: string;
    channel: string;
    player: null | ChildNode = null;
    pc: null | RTCPeerConnection = null;
    dc: null | RTCDataChannel = null;
    audio_senders: RTCRtpSender[] = []
    audio: null | HTMLMediaElementWithCaptureStream = null;

    constructor(id: string, nickname: string, mmsi: string, channel: string) {
        this.id = id;
        this.mmsi = mmsi;
        this.nickname = nickname;
        this.channel = channel;
    }

    playAudio(src: string) {
        if (!this.pc)
            return;
        this.stopAudio()
        const audio = new Audio(src) as HTMLMediaElementWithCaptureStream
        const capturedStream = audio.captureStream()
        audio.volume = useAudio().generalVolume.value
        this.audio_senders = []
        this.audio = audio;
        audio.currentTime = 0;
        audio.play().then(() => {
            for (let i = 0; i < capturedStream.getAudioTracks().length; i++) {
                const audiotrack = capturedStream.getAudioTracks()[i]
                if (this.pc)
                    this.audio_senders.push(this.pc.addTrack(audiotrack))
            }
        })

        return this.audio;
    }

    stopAudio() {
        if (this.audio && this.pc) {
            this.audio.pause()
            for (let sender of this.audio_senders) {
                this.pc.removeTrack(sender)
            }
            this.audio = null;
            this.audio_senders = []
        }
    }

    selfDestroy() {
        this.stopAudio();

        if (this.player) {
            this.player.remove();
        }

        if (this.pc) {
            this.pc.close();
            this.pc.onicecandidate = null;
            this.pc.ontrack = null;
            this.pc = null;
        }
    }

    sendMessage(message: string) {
        if (this.dc) {
            this.dc.send(message);
        }
    }
}