export function useRecorder(stream: MediaStream, mmsi: string, atEnd: (file: File) => void) {
    let lastdate = Date.now();
    let recordedData: any[] = [];
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.ondataavailable = (event) => {
        recordedData.push(event.data)
    }
    mediaRecorder.onerror = (error) => {
        console.error(error)
    }
    mediaRecorder.addEventListener('error', (error) => {
        console.error({ mediaRecorderError: error })
    })

    function createFileFormCurrentRecordedData() {
        if (Date.now() - lastdate > 1000 && recordedData.length) {
            console.log("WRITTING AUIDIO FILE ", mmsi)
            const blob = new Blob(recordedData, { type: "audio/webm" });
            const file = new File([blob], "yourfilename.weba", { type: "audio/webm" });
            recordedData = []
            console.log('AUDIO RECORDED', file)
            atEnd(file);
        }
        lastdate = Date.now()
    }

    function start() {
        if (mediaRecorder.state != 'recording') {
            lastdate = Date.now();
            try {
                mediaRecorder.start();
            } catch (e) {
                console.error(e)
            }
        }
    }

    function stop() {
        try {
            mediaRecorder.stop();
        } catch (e) {
            console.error(e)
        }
    }

    mediaRecorder.onstop = createFileFormCurrentRecordedData;

    return { start, stop }
}