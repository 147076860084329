import { usePage } from "@/composables/page";
import { convertLatToCoord, convertLngToCoord, convertMilesToMeters, converterCoordenadasLatitude, converterCoordenadasLongitude, getDegrees, getDirection, getMinutes } from "./utils";

const { L } = window as any;

export class Boat {
    id: string;
    socketId: string;
    nickname: string;
    shape: any;
    signal_shape: any;
    map: any;
    lat: number;
    lng: number;
    cog: number;
    signal_coverage: number;
    sog: number;
    antenna_height: number;
    _onUpdate: (() => {})[];
    tooltip: any;
    asRoot: boolean;
    _canCommunicateWithMe: boolean;

    constructor(id: string, nickname: string, lat: string | number, lng: string | number, cog: string | number, signal_coverage: string | number, socketId: string, asRoot?: boolean) {
        this.id = id;
        this.nickname = nickname
        this.shape = null;
        this.signal_shape = null;
        this.map = null;
        this.lat = parseFloat(lat.toString());
        this.lng = parseFloat(lng.toString());
        this.cog = parseFloat(cog.toString());
        this.signal_coverage = parseFloat(signal_coverage.toString());
        this.sog = 0;
        this.antenna_height = 10;//meters
        this._onUpdate = [];
        this.tooltip = false
        this.asRoot = asRoot === true || (asRoot || '').toString() === 'true' ? true : false;
        this._canCommunicateWithMe = false
        this.socketId = socketId;
    }

    onUpdate(f: any) {
        this._onUpdate.push(f);
    }

    sendUpdate() {
        for (let f of this._onUpdate) {
            f()
        }
    }

    setMap(map: any) {
        this.map = map;
        this.repaint()
    }

    setMMSI(mmsi: string) {
        this.id = mmsi;
        this.tooltip.setContent((this.asRoot ? '★ TEACHER ★<br/>' : '') + "" + this.nickname + ' (' + (this._canCommunicateWithMe ? 'connected' : 'so far') + ')<br/>' + this.id + "<br/>")
    }

    getBoatShape(lat: number, lng: number, cog: number) {
        const boatSize = 0.0002;
        var radians = cog * (Math.PI / 180);
        const shape = [
            [lat + boatSize / 2, lng],
            [lat, lng + boatSize / 4],
            [lat - boatSize, lng + boatSize / 4],
            [lat - boatSize * 1.1, lng + boatSize / 8],
            [lat - boatSize * 1.1, lng - boatSize / 8],
            [lat - boatSize, lng - boatSize / 4],
            [lat, lng - boatSize / 4],
        ]
        for (let s of shape) {
            var x = s[0] - lat;
            var y = s[1] - lng;
            s[0] = x * Math.cos(radians) - y * Math.sin(radians) + lat;
            s[1] = (x * Math.sin(radians) + y * Math.cos(radians)) / Math.cos(s[0] * (Math.PI / 180)) + lng;
        }

        return shape
    }

    set canCommunicateWithMe(value) {
        this._canCommunicateWithMe = value
        this.tooltip.setContent((this.asRoot ? '★ TEACHER ★<br/>' : '') + "" + this.nickname + ' (' + (value ? 'connected' : 'so far') + ')<br/>' + this.id + "<br/>")

        const rtc = usePage().playersRTCs.value[this.id];
        if (rtc) {
            rtc.video.volume = this._canCommunicateWithMe ? 1 : 0
        } else {
            console.error("VIDEO NOT FOUND ID", `webrtc-player-${this.id}`)
        }

    }

    get canCommunicateWithMe() {
        return this._canCommunicateWithMe
    }

    get latDegrees() {
        const latstr = this.getLatCoord()
        return getDegrees(latstr)
    }

    get lngDegrees() {
        const lngstr = this.getLngCoord()
        return getDegrees(lngstr)
    }

    set lngDegrees(val) {
        let lngstr = this.getLngCoord()
        this.lng = converterCoordenadasLongitude(val * 1, getMinutes(lngstr), getDirection(lngstr))
    }

    set latDegrees(val) {
        let latstr = this.getLatCoord()
        this.lat = converterCoordenadasLatitude(val * 1, getMinutes(latstr), getDirection(latstr))
    }

    get latMinutes() {
        const latstr = this.getLatCoord()
        return getMinutes(latstr)
    }

    get lngMinutes() {
        const lngstr = this.getLngCoord()
        return getMinutes(lngstr)
    }

    set latMinutes(val) {
        let latstr = this.getLatCoord()
        this.lat = converterCoordenadasLatitude(getDegrees(latstr), val * 1, getDirection(latstr))
    }

    set lngMinutes(val) {
        let lngstr = this.getLngCoord()
        this.lng = converterCoordenadasLatitude(getDegrees(lngstr), val * 1, getDirection(lngstr))
    }

    get latNS() {
        const latstr = this.getLatCoord()
        return getDirection(latstr)
    }
    get lngWE() {
        const lngstr = this.getLngCoord()
        return getDirection(lngstr)
    }

    set latNS(val) {
        let latstr = this.getLatCoord()
        this.lat = converterCoordenadasLatitude(getDegrees(latstr), getMinutes(latstr), val)
    }

    set lngWE(val) {
        let lngstr = this.getLngCoord()
        this.lng = converterCoordenadasLongitude(getDegrees(lngstr), getMinutes(lngstr), val)
    }

    getLatCoord() {
        return convertLatToCoord(this.lat);
    }

    getLngCoord() {
        return convertLngToCoord(this.lng)
    }

    getAntennaHeightCoord() {
        return `${this.antenna_height}m`
    }

    getCogCoord() {
        return `${parseFloat(this.cog + '').toFixed(3)}°`
    }

    getSogCoord() {
        return `${this.sog}kts`
    }

    getCoverageCoord() {
        return `${parseFloat(this.signal_coverage + '').toFixed(2)} miles`
    }

    setSignalCoverage(signal_coverage: number | string) {
        this.signal_coverage = parseFloat(signal_coverage.toString());
        if (this.signal_shape) {
            this.signal_shape.setRadius(convertMilesToMeters(this.signal_coverage))
        }
        for (let f of this._onUpdate) {
            f()
        }
    }

    setLatLngCog(lat: string | number, lng: string | number, cog: string | number, sog: string | number, signal_coverage: string | number) {
        this.lat = parseFloat(lat.toString());
        this.lng = parseFloat(lng.toString());
        this.cog = parseFloat(cog.toString());
        this.sog = parseFloat(sog.toString());
        this.signal_coverage = parseFloat(signal_coverage.toString());
        for (let f of this._onUpdate) {
            f()
        }
        this.repaint()
    }

    repaint() {
        if (this.map) {
            if (!this.shape) {
                this.shape = L.polygon(this.getBoatShape(this.lat, this.lng, this.cog), { color: "black", weight: 1, fillColor: this.asRoot ? "green" : "white", fillOpacity: 0.6 }).addTo(this.map)
                this.signal_shape = L.circle([this.lat, this.lng], { color: 'blue', fill: false, radius: convertMilesToMeters(this.signal_coverage) }).addTo(this.map);
                this.tooltip = L.tooltip([this.lat, this.lng], { content: (this.asRoot ? '★ TEACHER ★<br/>' : '') + "" + this.nickname + '<br/>' + this.id, permanent: true })
                    .addTo(this.map);
            } else {
                this.shape.setLatLngs(this.getBoatShape(this.lat, this.lng, this.cog))
                this.signal_shape.setLatLng([this.lat, this.lng])
                this.signal_shape.setRadius(convertMilesToMeters(this.signal_coverage))
                this.tooltip.setLatLng([this.lat, this.lng])
            }
        }
    }

    destroy() {
        console.log("DESTROYING", this)
        this.map.removeLayer(this.shape);
        this.map.removeLayer(this.tooltip);
        this.map.removeLayer(this.signal_shape);
    }

    //delta in mileseconds
    move(delta: number) {
        const degree = this.cog * (Math.PI / 180)
        const mpersec = this.sog * 0.514444 //convert knot/s to m/s
        const deltas = delta / 1000 //convert mileseconds to seconds
        const move = (deltas * mpersec) //meters moved
        const move_lat = 1 / 111111
        const move_lng = 1 / (111111 * Math.cos(this.lat * (Math.PI / 180)))

        this.lat += Math.cos(degree) * move * move_lat
        this.lng += Math.sin(degree) * move * move_lng
        this.repaint()
        for (let f of this._onUpdate) {
            f()
        }
    }

}