<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useSession } from "./composables/session"
import LoginView from "./views/LoginView.vue";
import { usePage } from "./composables/page";
const room = useSession().room
const isLogged = computed(() => !!room.value)
const previewPlayer = ref(null as null | HTMLVideoElement);
const failToConnect = ref(false)

onMounted(async () => {
  //WILL REQUEST DE MIC PERMISSION
  navigator.mediaDevices
    .getUserMedia({
      audio: true,
    })
    .then(function (stream: any) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(function (copystream: any) {
          useSession().setSelfStream(stream, copystream);
          useSession().muteMic(true);
          if (previewPlayer.value && useSession().myStream.value)
            previewPlayer.value.srcObject = useSession().myStream.value!.stream;
        })
        .catch(function (err) {
          failToConnect.value = true;
        });
    })
    .catch(function (err) {
      failToConnect.value = true;
    });
})

</script>
<template>

  <div class="flex flex-col items-center justify-center w-full h-full text-center px-4"
    style="font-size: 32px;padding-left: 40px;padding-right: 40px; font-weight: bold;color:white"
    v-if="failToConnect">
    <p>We can't connect you because we don't find your microphone.</p>
    <ul style="font-size: 16px;list-style: disc;" class="text-left">
      <li>1. Make sure your microphone is plugged in and set up to work.</li>
      <li>2. Make sure your browser is allowed to access the mic</li>
      <li>3. Make sure your page has been allowed to acess the mic by browser settings</li>
      <li>4. Reload the page or try in another browser</li>
    </ul>
  </div>
  <template v-else>
    <login-view v-if="!isLogged" />
    <router-view v-else />
  </template>
  <div class="videoWrapper">
    <video ref="previewPlayer" class="user-div" autoplay muted></video>
  </div>
</template>

<style></style>
