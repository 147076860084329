import { computed, ref, watch } from "vue";

const logs = ref([] as Log[])

export function useLogs() {
    const receivedLogs = computed(() => logs.value.filter(d => d.direction == 'received'))
    const sentLogs = computed(() => logs.value.filter(d => d.direction == 'sent'))
    function total(direction: string, types: string[]) {
        return logs.value.filter(d => d.direction == direction && types.indexOf(d.type) != -1)
    }
    return { logs, receivedLogs, sentLogs, total }
}