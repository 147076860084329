import Emitter from "socket.io-client"

import { Ref, ref, watch } from "vue"
import { User } from "@/libs/user"
import { Boat } from "@/libs/boat"
import { usePage } from "./page"
import { useAudio } from "./audio"
import { useRecorder } from "./recorder"
import { useLogs } from "./logs"
import { convertLatToCoord, convertLngToCoord } from "@/libs/utils"
const room = ref("")
const nickname = ref("")
const mmsi = ref("")
const asRoot = ref(false)
const channel = ref("")
const distress = ref(null as null | IDistressMessage)
const distressAck = ref(null as null | IDistressAckMessage)

const mics = ref({} as { [key: string]: boolean });
const channelSocket = ref(null as null | SocketIO)
const channel16Socket = ref(null as null | SocketIO)
const roomSocket = ref(null as null | SocketIO)
const myStream = ref(null as null | { stream: (MediaStream & { enabled: boolean }), startRecord: () => void, stopRecord: () => void })
const audioStream = ref(null as null | MediaStream)
const users = ref(new Map() as Map<string, any>);
const GPSSource = ref("automatic" as "automatic" | "manual")
const GPSManualCoord = ref({ lat: "041°21.182'N", lng: "002°10.995'E" })
const boat = ref(new Boat(mmsi.value, nickname.value, 41.3520246, 2.1808047, 90, 2, ''))
const boats = {} as { [key: string]: Boat }
const token = ref('')
const dualWatch = ref(false)
const downloadingAudio = ref(false)
const soundSource = ref(null as null | AudioBufferSourceNode)
const individualCall = ref(null as null | { mmsi: string, channel: number, ack?: { channel?: number, able: boolean } })
const individualCallTransmission = ref(null as null | { mmsi: string, channel: number, date: number })
const individualSocket = ref(null as null | { socket: SocketIO, individual_channel: string, mmsi: string, channel: string })
let iceServers: RTCConfiguration['iceServers'] | null = null
const { io } = window as any as { io: typeof Emitter };


function HTTP_GET(url: string): any {
    return new Promise((res, rej) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.send();

        xhr.onerror = function (e) {
            rej(e)
        }

        xhr.onload = function () {
            if (xhr.status != 200) {
                rej("Unexpected status code " + xhr.status + " for " + url)
                return false;
            }

            res(xhr.response);
        };
    })
}

const requestRTC = async function () {
    const response = await fetch("https://jhonny.metered.live/api/v1/turn/credentials?apiKey=3748e93794f92aad3886bf329f1ec02d1d9f&region=global", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }
    })
    iceServers = await response.json();
}

function createPeer(user: User, socket: SocketIO) {
    console.log("Creating PEER with", user)
    var pc = new RTCPeerConnection(iceServers ? { iceServers } : { iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });

    pc.onicecandidate = function (event: RTCPeerConnectionIceEvent) {
        if (!event.candidate) {
            return;
        }
        // console.log('[SCKT] EMIT CANDIDATE')
        socket.emit("candidate", {
            mmsi: user.mmsi,
            id: user.id,
            candidate: event.candidate,
        });
    };

    if (audioStream.value) {
        for (let track of audioStream.value.getTracks()) {
            (audioStream.value as any).enabled = true;
            pc.addTrack(track, audioStream.value);
            console.log("ADDING AUDIO STREAM", track)
        }
    } else if (myStream.value) {
        for (let track of myStream.value.stream.getTracks()) {
            myStream.value.stream.enabled = false;
            pc.addTrack(track, myStream.value.stream);
        }
    }
    pc.ontrack = function (event: RTCTrackEvent) {
        if (user.player) {
            return;
        }
        // console.log("[PEER] ON TRACK", event)
        user.player = usePage().addVideoPlayer(event.streams[0], user.nickname, user.mmsi);
    };

    pc.ondatachannel = function (event) {
        user.dc = event.channel;
        setupDataChannel(user.dc);
    };

    return pc;
}

function createOffer(user: User, socket: SocketIO) {
    if (user.pc) {
        user.dc = user.pc.createDataChannel("chat");
        setupDataChannel(user.dc);
        // console.log("[PEER] CREATE OFFER")

        user.pc.createOffer().then(function (offer: any) {
            if (user.pc) {
                user.pc.setLocalDescription(offer).then(function () {
                    // console.log('[SCKT] EMIT OFFER')
                    socket.emit("offer", {
                        mmsi: mmsi.value,
                        id: user.id,
                        offer: offer,
                    });
                });
            }
        });
    }
}

function answerPeer(user: User, offer: any, socket: SocketIO) {
    // console.log("[PEER] ANSWER OFFER")
    if (user.pc) {
        user.pc.setRemoteDescription(offer).then(function () {
            if (user.pc) {
                user.pc.createAnswer().then(function (answer: any) {
                    if (user.pc) {
                        user.pc.setLocalDescription(answer).then(function () {
                            // console.log('[SCKT] EMIT ANSWER')
                            socket.emit("answer", {
                                mmsi: user.mmsi,
                                id: user.id,
                                answer: answer,
                            });
                        });
                    }
                });
            }
        });
    }
}

function setupDataChannel(dataChannel: RTCDataChannel) {
    dataChannel.onopen = checkDataChannelState;
    dataChannel.onclose = checkDataChannelState;
    dataChannel.onmessage = function (e: { data: any }) {
        // console.log(e.data)
        usePage().messages.value.push(e.data)
    };
}

function checkDataChannelState(dataChannel: { type: string }) {
    // console.log("WebRTC channel state is:", dataChannel.type);
}


function sendBoatUpdate() {
    if (roomSocket.value && boat.value)
        roomSocket.value.emit("update_room_user", {
            channel: channel.value,
            nickname: nickname.value,
            mmsi: mmsi.value,
            room: room.value,
            lat: boat.value.lat,
            lng: boat.value.lng,
            cog: boat.value.cog,
            signal_coverage: boat.value.signal_coverage,
            asRoot: asRoot.value,
        });
}

export function useSession() {

    function setSelfStream(stream: any, copystream: any) {
        const { start, stop } = useRecorder(copystream, mmsi.value, (file) => { useAudio().pushSentAudios({ file, created: new Date(), mmsi: mmsi.value, channel: channel.value }) })
        myStream.value = { stream, startRecord: start, stopRecord: stop };
    }

    function singin(_room: string, _nickname: string, _mmsi: string, _asRoot: boolean) {
        // console.log("SIGNIN", { _room, _nickname, _mmsi, _asRoot })
        room.value = _room;
        nickname.value = _nickname;
        mmsi.value = _mmsi;
        asRoot.value = _asRoot;
    }


    function muteMic(muted: boolean) {
        if (myStream.value)
            myStream.value.stream.getAudioTracks().forEach((track: any) => { track.enabled = !muted; });
    }

    function getIO(query: { [key: string]: any }) {
        return process.env.VUE_APP_ENV == 'DEV' ? io("http://localhost:3000/", { query }) : io({ query });
    }

    function initServerRoomConnection(initial_channel: string, onUpdate: (data: IUpdateBoatMessage) => void, onDisconnect: (data: { mmsi: string }) => void) {
        boat.value = new Boat(mmsi.value, nickname.value, 41.3520246 + Math.random() * 0.01 - 0.005, 2.1808047 + Math.random() * 0.01 - 0.005, Math.random() * 360, 2, '', asRoot.value);
        var newSocketRoom = getIO({
            type: "room",
            room: room.value,
            channel: initial_channel,
            nickname: nickname.value,
            mmsi: mmsi.value,
            lat: boat.value.lat,
            lng: boat.value.lng,
            cog: boat.value.cog,
            signal_coverage: boat.value.signal_coverage,
            asRoot: asRoot.value,
        });

        roomSocket.value = newSocketRoom;

        newSocketRoom.on('individual_call', function (data: { source: string, channel: number, date: number }) {
            if (!individualSocket.value && !individualCallTransmission.value) {
                individualCallTransmission.value = { mmsi: data.source, channel: data.channel, date: data.date };
                useLogs().logs.value.push({ direction: 'received', type: 'dsc-individual-call', channel: data.channel.toString(), date: new Date(data.date), mmsi: data.source })
            }
        })

        newSocketRoom.on('individual_call_connect', function (data: { source_mmsi: string, target_mmsi: string, channel: string }) {
            individualCall.value = null;
            individualCallTransmission.value = null;
            initIndividualServerConnection(data.source_mmsi, data.target_mmsi, data.channel)
        })

        newSocketRoom.on('individual_call_ack', function (data: { able: boolean, channel?: number }) {
            if (individualCall.value) {
                individualCall.value.ack = { able: data.able, channel: data.channel };
                if (data.able && data.channel)
                    useLogs().logs.value.push({ direction: 'received', type: 'dsc-individual-ack', channel: data.channel.toString(), date: new Date(), mmsi: individualCall.value.mmsi })
            }
        })

        newSocketRoom.on("update_room_user", function (data: IUpdateBoatMessage) {
            if (data.mmsi !== mmsi.value) {
                if (!('new_mmsi' in data))
                    usePage().updateRoomPlayer(data.mmsi, data.nickname, data.channel, data.asRoot);
                if (onUpdate) onUpdate(data);
            }
        });

        newSocketRoom.on("change_mmsi", function (data: { id: string, old_mmsi: string, new_mmsi: string }) {
            console.log("[SCKT ROOM] CHANGE MMSI");
            if (mmsi.value == data.old_mmsi) {
                mmsi.value = data.new_mmsi;
                boat.value.canCommunicateWithMe = true
                boat.value.setMMSI(data.new_mmsi)
                boat.value.repaint()
            } else {
                console.log(Array.from(users.value.values()), data.old_mmsi)
                const find: User = Array.from(users.value.values()).find((d: User) => {
                    console.log(`${d.mmsi} == ${data.old_mmsi}`)
                    return d.mmsi == data.old_mmsi
                })
                console.log({ find })
                if (find) {
                    const user = users.value.get(find.id)
                    console.log({ user })
                    if (user) {
                        user.mmsi = data.new_mmsi;
                    }
                    if (onUpdate)
                        onUpdate({ new_mmsi: data.new_mmsi, mmsi: data.old_mmsi } as IUpdateBoatMessageNewMSSI)
                }
            }
        });

        newSocketRoom.on("disconnect-user", function (data: { id: string, mmsi: string }) {
            // console.log("[SCKT ROOM] DISCONNECT", data);
            usePage().removeRoomPlayer(data.mmsi);
            if (onDisconnect) onDisconnect(data);
        });

        newSocketRoom.on("request_room_user", function (data: { mmsi: string }) {
            if (data.mmsi !== mmsi.value) {
                // console.log("[SCKT ROOM] REQUEST ROOM USER", data);
                sendBoatUpdate();
            }
        });

        newSocketRoom.on("request_socket_id", function (data: { id: string }) {
            console.log("SET socket ID", data.id)
            boat.value.socketId = data.id
        });

        newSocketRoom.on("distress", function (data: IDistressMessage) {
            if (boats[data.mmsi] && boats[data.mmsi].canCommunicateWithMe) {
                console.log({ data })
                useLogs().logs.value.push({ direction: 'received', type: 'distress', date: new Date(data.date), mmsi: data.mmsi, lat: convertLatToCoord(parseFloat(data.lat)), lng: convertLngToCoord(parseFloat(data.lng)), nature: data.nature })
                distress.value = data;

                useAudio().distressAudio.value.play();
            }
        });

        newSocketRoom.on("distress_ack", (data: IDistressAckMessage) => {
            console.log({ data })
            useLogs().logs.value.push({ direction: 'received', type: 'distress-ack', date: new Date(data.date), mmsi: data.mmsi, lat: convertLatToCoord(parseFloat(data.lat)), lng: convertLngToCoord(parseFloat(data.lng)) })
            distressAck.value = data;
        });

        usePage().updateRoomPlayer(mmsi.value, nickname.value, initial_channel, asRoot.value);

        newSocketRoom.emit("request_room_user", { mmsi: mmsi.value });
        newSocketRoom.emit("request_socket_id", {});

        boat.value.onUpdate(() => {
            sendBoatUpdate();
        });
    }

    function initIndividualServerConnection(source_mmsi: string, target_mmsi: string, individual_channel: string) {
        if (dualWatch.value) {
            dualWatch.value = false;
            if (channel16Socket.value) {
                clearChannelUsers('16')
                channel16Socket.value.close();
                channel16Socket.value = null;
            }
        }
        if (channelSocket.value) {
            clearChannelUsers(channel.value)
            channelSocket.value.close();
            channelSocket.value = null;
        }
        const channel_string = `${source_mmsi}UP${target_mmsi}DOWN${individual_channel}`
        individualSocket.value = { socket: initServerChannelConnection(channel_string), individual_channel, channel: channel_string, mmsi: target_mmsi == mmsi.value ? source_mmsi : target_mmsi };
    }

    function initServerChannelConnection(newchannel: string): SocketIO {
        console.log("INIT CONNECTION WITH", newchannel)
        //CRIA O MMSI de 9 digitos (224 é o prefixo da espanha)
        Object.keys(mics.value).map((key: string) => {
            mics.value[key] = false;
        });

        var newSocketChannel = getIO({
            type: "channel",
            room: room.value,
            channel: newchannel,
            nickname: nickname.value,
            mmsi: mmsi.value,
        });

        newSocketChannel.on("disconnect-user", function (data: { id: string }) {
            console.log("[SCKT CHANNEL] DISCONNECT");

            const user = users.value.get(data.id);

            if (user) {
                users.value.delete(data.id);
                user.selfDestroy();
            }

            if (individualSocket.value) {
                closeIndividualSocket()
            }
        });

        newSocketChannel.on("mic_status_changed", function (data: IMicStatusChangeMessage) {
            // console.log('mic_status_changed', data)
            if (data.mmsi) {
                if (data.status) {
                    if (!mics.value[data.mmsi]) {
                        mics.value[data.mmsi] = true;
                        if (data.mmsi != mmsi.value && usePage().playersRTCs.value[data.mmsi])
                            usePage().playersRTCs.value[data.mmsi].startRecord()
                    }
                } else {
                    if (mics.value[data.mmsi]) {
                        mics.value[data.mmsi] = false;
                        if (data.mmsi != mmsi.value && usePage().playersRTCs.value[data.mmsi])
                            usePage().playersRTCs.value[data.mmsi].stopRecord()
                    }
                }
            }
        });

        //EVENT TRIGGERED WHEN SOMEONE CONNECT TO THE CHANNEL
        newSocketChannel.on("call", function (data: { id: string, nickname: string, mmsi: string }) {
            console.log("[SCKT CHANNEL] CALL", data);
            if (data.mmsi != mmsi.value) {
                console.log("MMSI ", data.mmsi, " WANTS TO CONNECT WITH YOU")
                const user = new User(data.id, data.nickname, data.mmsi, newchannel);
                user.pc = createPeer(user, newSocketChannel);
                users.value.set(data.id, user);
                createOffer(user, newSocketChannel);
            } else {
                console.log("CALL DISCARTED BECAUSE IS THE SAME MMSI");
            }
        });

        //EVENT TRIGGERED WHEN SOMEONE WANTS TO CONNECT WITH YOU
        newSocketChannel.on("offer", function (data: { id: string, nickname: string, mmsi: string, offer: any }) {
            console.log("[SCKT CHANNEL] OFFER", data);
            var user = users.value.get(data.id);
            // console.log("FOUNDED", user);
            if (user) {
                answerPeer(user, data.offer, newSocketChannel);
            } else {
                let user = new User(data.id, data.nickname, data.mmsi, newchannel);
                user.pc = createPeer(user, newSocketChannel);
                users.value.set(data.id, user);
                answerPeer(user, data.offer, newSocketChannel);
            }
        });

        //ANSWER OF THE WISH TO CONNECT
        newSocketChannel.on("answer", function (data: { id: string, answer: string }) {
            console.log("[SCKT CHANNEL] ANSWER");
            var user = users.value.get(data.id);
            if (user) {
                user.pc.setRemoteDescription(data.answer);
            }
        });

        //TRIGGERED WHEN THE RTC PEER HAS BEEN CONNECTED
        newSocketChannel.on("candidate", function (data: { id: string, nickname: string, mmsi: string, candidate: RTCIceCandidate }) {
            console.log("[SCKT CHANNEL] CANDIDATE", data);
            var user = users.value.get(data.id);
            if (user) {
                console.log("User already exists. setting only candidate")
                user.pc.addIceCandidate(data.candidate);
            } else {
                console.log("User doens't already exits. setting up")
                let user = new User(data.id, data.nickname, data.mmsi, newchannel);
                user.pc = createPeer(user, newSocketChannel);
                user.pc.addIceCandidate(data.candidate);
                users.value.set(data.id, user);
            }
        });

        newSocketChannel.on("connect", function () {
            // console.log("[SCKT CHANNEL] CONNECT");
            // showPlayers();
            sendBoatUpdate();
            usePage().updateRoomPlayer(mmsi.value, nickname.value, channel.value);
        });

        newSocketChannel.on("connect_error", function (error: any) {
            // console.log("[SCKT CHANNEL] CONNECT ERROR");
            alert("[SOCKET.IO] CONNECTION FAILED")
            // console.log(error);
            leave();
        });
        return newSocketChannel;
        // channelSocket.value = newSocketChannel;
    }

    function sendDistressAck() {
        if (roomSocket.value && distress.value) {
            const { boat } = useSession()
            roomSocket.value.emit("distress_ack", {
                destination: distress.value.source,
                date: (new Date()).toISOString(),
                mmsi: boat.value.id,
                lat: boat.value.lat,
                lng: boat.value.lng
            } as IDistressAckMessage | { destination: string });
            useLogs().logs.value.push({
                direction: 'sent',
                type: 'distress-ack',
                date: new Date(),
                mmsi: distress.value.mmsi,
                lat: boat.value.getLatCoord(),
                lng: boat.value.getLngCoord()
            })
        }
    }

    function sendDistress(nature: number, date: Date) {
        const roomSocket = useSession().roomSocket
        if (roomSocket.value) {
            const boat = useSession().boat.value
            roomSocket.value.emit("distress", {
                nature: nature,
                mmsi: boat.id,
                lat: boat.lat.toString(),
                lng: boat.lng.toString(),
                date: date.toISOString()
            } as IDistressMessage);
            useLogs().logs.value.push({
                direction: 'sent',
                type: 'distress',
                date: new Date(),
                mmsi: boat.id,
                lat: boat.getLatCoord(),
                lng: boat.getLngCoord(),
                nature: nature
            })
        }
    }

    function sendIndividualRequest() {
        if (individualCall.value) {
            if (boats[individualCall.value.mmsi] && roomSocket.value) {
                const boat = boats[individualCall.value.mmsi]
                roomSocket.value.emit('individual_call', { destination: boat.socketId, source: mmsi.value, channel: individualCall.value.channel, date: Date.now() })
            }
            useLogs().logs.value.push({ direction: 'sent', type: 'dsc-individual-call', channel: individualCall.value.channel.toString(), date: new Date(), mmsi: individualCall.value.mmsi })
        }
    }

    function sendIndividualRequestAck(able: boolean, channel?: number) {
        if (individualCallTransmission.value) {
            if (boats[individualCallTransmission.value.mmsi] && roomSocket.value) {
                const boat = boats[individualCallTransmission.value.mmsi]
                roomSocket.value.emit('individual_call_ack', { destination: boat.socketId, able, channel })
            }
            useLogs().logs.value.push({ direction: 'received', type: 'dsc-individual-ack', channel: channel?.toString() || individualCallTransmission.value.channel.toString(), date: new Date(), mmsi: individualCallTransmission.value.mmsi })
        }
    }

    function sendIndividualRequestConnection() {
        if (individualCall.value && boats[individualCall.value.mmsi] && roomSocket.value && individualCall.value.ack) {
            const boat = boats[individualCall.value.mmsi]
            roomSocket.value.emit('individual_call_connect', { destination: boat.socketId, source_mmsi: mmsi.value, target_mmsi: individualCall.value.mmsi, channel: individualCall.value.ack.channel })
        }
    }

    function closeIndividualSocket() {
        if (individualSocket.value) {
            individualSocket.value.socket.close();
            clearChannelUsers(individualSocket.value.channel)
            individualSocket.value = null;
        }
        channelSocket.value = initServerChannelConnection(channel.value);
    }

    function leave() {
        if (channelSocket.value)
            channelSocket.value.close();
        if (roomSocket.value)
            roomSocket.value.close();
        for (var user of users.value.values()) {
            user.selfDestroy();
        }
        users.value.clear();
        if (channel16Socket.value) {
            channel16Socket.value.close();
        }
        room.value = "";
        nickname.value = "";
        mmsi.value = "";
        asRoot.value = false;
        mics.value = {}
        channelSocket.value = null;
        channel16Socket.value = null;
        roomSocket.value = null;

    }

    function changeChannel(channel_id: string) {
        if (dualWatch.value) {
            if (channel_id == '16') {
                if (channel16Socket.value) {
                    channel16Socket.value.close();
                    clearChannelUsers('16')
                    channel16Socket.value = null;
                }
            } else if (!channel16Socket.value) {
                channel16Socket.value = initServerChannelConnection('16');
            }
        }
        if (channelSocket.value) {
            channelSocket.value.close();
        }
        clearChannelUsers(channel.value)
        if (room.value && nickname.value) {
            channelSocket.value = initServerChannelConnection(channel_id);
            channel.value = channel_id;
        } else {
            alert("Room name and nick name are required fields");
        }
    }

    function mic_status_changed(mmsi: string, status: boolean) {
        if (channelSocket.value) {
            channelSocket.value.emit("mic_status_changed", { mmsi: mmsi, status: status });
            if (channel16Socket.value) {
                channel16Socket.value.emit("mic_status_changed", { mmsi: mmsi, status: status });
            }
        }
    }


    async function triggerAudio(src: string) {

        const context = new AudioContext();
        const gainNode = context.createGain();
        gainNode.connect(context.destination);

        gainNode.gain.value = 0.1;
        downloadingAudio.value = true
        const array_buffer: ArrayBuffer = await HTTP_GET(src);
        downloadingAudio.value = false

        // Import callback function that provides PCM audio data decoded as an audio buffer
        context.decodeAudioData(array_buffer, function (buffer) {
            // Create the sound source
            const newsoundSource = context.createBufferSource();

            newsoundSource.buffer = buffer;
            newsoundSource.start(0);
            newsoundSource.connect(gainNode);

            const destination = context.createMediaStreamDestination();
            newsoundSource.connect(destination);
            soundSource.value = newsoundSource
            audioStream.value = destination.stream;
            reinitChannel();
        });
        // console.log("EMITTING", src)
        // if (channelSocket.value) {
        //     channelSocket.value.emit("trigger_audio", { src, channel: channel.value, mmsi: mmsi.value })
        //     if (channel16Socket.value) {
        //         channel16Socket.value.emit("trigger_audio", { src, channel: '16', mmsi: mmsi.value })
        //     }
        // }
    }

    function cancelAudio() {
        soundSource.value?.stop();
        audioStream.value = null;
        reinitChannel();
        // if (channelSocket.value) {
        //     channelSocket.value.emit("trigger_stop_audio", { channel: channel.value, mmsi: mmsi.value })
        //     if (channel16Socket.value) {
        //         channel16Socket.value.emit("trigger_stop_audio", { channel: '16', mmsi: mmsi.value })
        //     }
        // }
    }

    function reinitChannel() {
        if (channel16Socket.value) {
            channel16Socket.value.close();
            clearChannelUsers('16')
            channel16Socket.value = initServerChannelConnection('16');
        }
        if (channelSocket.value) {
            channelSocket.value.close();
            clearChannelUsers(channel.value)
            channelSocket.value = initServerChannelConnection(channel.value);
        }
    }

    function clearChannelUsers(channel_id: string) {
        let toClear = []
        for (var user of users.value.values()) {
            if (user.channel == channel_id) {
                user.selfDestroy();
                toClear.push(user)
            }
        }
        for (var user of toClear) {
            users.value.delete(user.id)
        }
    }

    function setDualWatch(b: boolean) {
        dualWatch.value = b
        if (b) {
            if (channel.value != '16')
                channel16Socket.value = initServerChannelConnection('16');
        } else {
            if (channel16Socket.value) {
                channel16Socket.value.close();
                clearChannelUsers('16')
                channel16Socket.value = null;
            }
        }
    }

    function setNewMMSI(new_mmsi: string) {
        if (roomSocket.value) {
            roomSocket.value.emit("change_mmsi", { old_mmsi: mmsi.value, new_mmsi })
        }
    }

    return {
        leave,
        token,
        room,
        nickname,
        users,
        distress,
        distressAck,
        setSelfStream,
        triggerAudio,
        channel,
        mmsi,
        asRoot,
        myStream,
        boat,
        roomSocket,
        channelSocket,
        audioStream,
        mics,
        sendDistressAck,
        sendDistress,
        initServerRoomConnection,
        muteMic,
        mic_status_changed,
        changeChannel,
        singin,
        setNewMMSI,
        initServerChannelConnection,
        cancelAudio,
        setDualWatch,
        dualWatch,
        downloadingAudio,
        boats,
        GPSSource,
        GPSManualCoord,
        individualCall,
        requestRTC,
        individualSocket,
        closeIndividualSocket,
        sendIndividualRequest,
        sendIndividualRequestAck,
        individualCallTransmission,
        sendIndividualRequestConnection
    }
}

