import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'simulator',
    component: () => import('../views/SimulatorView.vue')
  }
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

export default router
