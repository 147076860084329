import { ref } from "vue";

const beepAudio = ref(new Audio("/sounds/beep.mp3"));
const staticAudio = ref(new Audio("/sounds/radio-static.mp3"));
const countdownAudio = ref(new Audio("/sounds/countdown.mp3"));
const staticSQLAudio = ref(new Audio("/sounds/radio-static.mp3"));
const distressAudio = ref(new Audio("/sounds/distress.wav"));
const ackAudio = ref(new Audio("/sounds/ack.mp3"));
const txAudio = ref(new Audio("/sounds/tx.wav"));
const routineAudio = ref(new Audio("/sounds/routine.wav"));
const btnAudio = ref([new Audio("/sounds/btn1.wav"), new Audio("/sounds/btn2.wav"), new Audio("/sounds/btn3.wav"), new Audio("/sounds/btn4.wav"), new Audio("/sounds/btn5.wav")]);
const receivedAudios = ref([] as IAudio[])
const sentAudios = ref([] as IAudio[])
const generalVolume = ref(0.5)

txAudio.value.volume = 0.25;

distressAudio.value.loop = true;
distressAudio.value.volume = 0.5;

staticAudio.value.loop = true;
staticAudio.value.volume = 0.05;

ackAudio.value.loop = true;
ackAudio.value.volume = 1;

routineAudio.value.loop = true;

staticSQLAudio.value.loop = true;
staticSQLAudio.value.volume = 0.2;

staticSQLAudio.value.addEventListener("timeupdate", function () {
    var buffer = 0.44;
    if (this.currentTime > this.duration - buffer) {
        this.currentTime = 0;
        this.play();
    }
});

export function useAudio() {

    function pushReceivedAudio(a: IAudio) {
        receivedAudios.value.push(a);
    }

    function pushSentAudios(a: IAudio) {
        sentAudios.value.push(a);
    }

    return { staticAudio, txAudio, ackAudio, btnAudio, staticSQLAudio, countdownAudio, beepAudio, distressAudio, generalVolume, routineAudio, pushReceivedAudio, pushSentAudios, receivedAudios, sentAudios }
}