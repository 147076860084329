import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Notifier } from '@airbrake/browser';

import './assets/sass/style.scss'

var airbrake = new Notifier({
    environment: 'production',
    projectId: 542683,
    projectKey: '97f2b1b5da4eef93f37ba55715695a55'
});

const $app = createApp(App)

$app.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
        error: err,
        params: { info: info }
    });
}

$app.use(router).mount('#app')
