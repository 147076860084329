<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useSession } from '@/composables/session';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

const loading = ref(false)

const usernameRef = ref("");
const passwordRef = ref("");
const nicknameRef = ref("");
const route = useRoute();

const room = computed(() => route.query.room?.toString())


async function load() {
    loading.value = true
    try {
        await useSession().requestRTC();
    } catch (e) {
        alert(e)
    }
    loading.value = false;
}

async function onSubmit(e: Event) {
    e.preventDefault();

    if (!room.value) {
        var username = usernameRef.value;
        var password = passwordRef.value;
        if (!username || !password) {
            alert("You must to provide username and password to create a room.");
            return;
        }
        const resp = await useApi().usePost('/login', { username: username, password: password }, { headers: { "Content-Type": "application/json" } })
        if (resp.error && resp.message) {
            alert(resp.message);
            return;
        }
        if (resp.success) {
            await load()
            useSession().token.value = resp.token;
            //SÓ AQUI QUE ENTRA DE FATO NA SALA
            useSession().singin(username, nicknameRef.value, "224" + Date.now().toString().substring(5, 11), true)

            if (useSession().room && useSession().nickname) {

                // useSession().initServerChannelConnection("01");
            } else {
                alert("Nick name are required");
            }
        }
    } else {
        await load()
        useSession().singin(room.value, nicknameRef.value, "224" + Date.now().toString().substring(5, 11), false)

        if (useSession().room && useSession().nickname) {
            // useSession().initServerChannelConnection("01");
        } else {
            alert("Nick name are required");
        }
    }
}

</script>
<template>
    <div class="welcome-div">
        <div v-if="loading" class="flex flex-col items-center justify-center w-full h-full text-center px-4"
            style="font-size: 32px;padding-left: 40px;padding-right: 40px; font-weight: bold;color:white">
            <p>Establishing connection with server</p>
        </div>
        <form v-else id="roomForm" @submit="onSubmit">
            <template v-if="!room">
                <div id="username" class="row input-field">
                    <input id="inputUsername" v-model="usernameRef" placeholder="Insert your username" type="text" />
                    <label for="inputUsername">Username:</label>
                </div>
                <br />
                <div id="password" class="row input-field">
                    <input id="inputPassword" v-model="passwordRef" placeholder="Insert your password" type="password" />
                    <label for="inputPassword">Password:</label>
                </div>
                <br />
            </template>
            <template v-else>
                <label>ROOM:</label>
                <h2 class="mb-5 mt-0">{{ room }}</h2>
            </template>
            <div class="row input-field">
                <input id="inputNickName" v-model="nicknameRef" placeholder="Insert your name" type="text" required />
                <label for="inputNickName">Your name:</label>
            </div>
            <br />
            <button type="submit" class="w-full btn-large waves-effect waves-dark cyan">Enter</button>
        </form>
    </div>
</template>
  